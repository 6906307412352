<template>
  <card :titulo="nomeGuia" v-if="estadoPossuiGuia">

    <card-content class="mb-2" :background="true">
      <div class="row gutters-xs">

        <v-input type="mask" class="col" :error="validation.guia" :disabled="!edicao"
                 label="Número da Guia" v-model="item.guia"
                 :masks="mascaras"
                 :inputProps="{ 'maxlength': tamanhoGuia}" @change="validarGuia()"/>

        <div class="w-100" />

        <v-input type="v-select" :options="tiposTribunal"
                 class="col-12" v-if="tiposTribunal.length" :disabled="!edicao"
                 label="Natureza" v-model="item.codigoTj" />

      </div>
    </card-content>

  </card>
</template>

<script>
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import RegrasTribunalBusiness from "@/business/RegrasTribunalBusiness";
export default {
  name: "VinculoGuiaTribunal",
  components: {Card, CardContent},
  props:{
    item:Object,
    edicao:Boolean,
    dominio:{type: String, default: ""},
    tipo:String,
    validation:{type: Object, default: () => ({})},
  },
  data(){
    return {
      tiposTribunal: [],
    }
  },

  async created() {
    if(this.$root.isEstadoPE){
      let response = await TipoServicoBusiness.getTiposAtoTribunalTratado(this.item);
      this.tiposTribunal = response.tiposTribunal;
      this.item.codigoTj = response.item.codigoTj;
    }
  },

  computed: {

    mascaras(){
      return RegrasTribunalBusiness.getMascarasGuiaTribunal(this.$root);
    },

    estadoPossuiGuia(){
      return this.$root.isEstadoPE || this.$root.isEstadoBA || this.$root.isEstadoSE;
    },

    nomeGuia(){

      if(this.$root.isEstadoPE){
        return 'SICASE';
      }

      if(this.$root.isEstadoBA || this.$root.isEstadoSE){
        return 'DAJE';
      }

    },

    tamanhoGuia(){
      return RegrasTribunalBusiness.getTamanhoGuiaTribunal(this.$root);
    }
  },

  methods:{
    validarGuia(){
      let guia = this.item.guia;
      this.$set(this.validation, 'guia', null);
      if(guia.length < this.tamanhoGuia){
        this.$set(this.validation, 'guia', 'Número da Guia com quantidade de caracteres inválidos');
      }
    }
  }

}
</script>
